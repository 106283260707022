let shadowRoot;

window.addEventListener("heyflow-init", (event) => {
    shadowRoot =
    (document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`)
        .shadowRoot) ||
    document;
});

window.Client =
{
    init: function() 
    {
     var url = window.location.href;
        
        const urlParams = new URLSearchParams(window.location.search);
        
        const one_choice  = urlParams.get('radio');
        const salle_de_bain  = urlParams.get('sdb');
        const cuisine  = urlParams.get('cuisine');
        const escaliers  = urlParams.get('escaliers');
        const salon_sejour  = urlParams.get('salon');
        const chambre  = urlParams.get('chambre');
        
       
        if(salle_de_bain=="on" || one_choice=="Sdb")
        {
          shadowRoot.getElementById("id-766a125d").checked = true; 
          shadowRoot.getElementById("id-18c4c8d5").click();
        }
        if(cuisine=="on" || one_choice=="Cuisine")
        {
          shadowRoot.getElementById("id-513cbd54").checked = true; 
        }
        if(escaliers=="on" || one_choice=="Escaliers")
        {
          shadowRoot.getElementById("id-911af93c").checked = true; 
        }
        if(salon_sejour=="on" || one_choice=="Salon")
        {
          shadowRoot.getElementById("id-9b61f36d").checked = true; 
        }
        if(chambre=="on" || one_choice=="Chambre")
        {
          shadowRoot.getElementById("id-49bcc1d7").checked = true; 
        }
    },
    
    onRender: function(amenagement) 
    {
        
       
    
    }
}